import { useState, useEffect } from "react";
import logo from "./../assets/logo.svg";
const Header = () => {
  const [menuShown, setMenuShown] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      if (position > 100) {
        setMenuHidden(true);
        setMenuShown(false);
      }
      if (position < scrollPosition) {
        setMenuHidden(false);
      }
      setScrollPosition(position);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  const clickMenuButton = () => {
    setMenuShown(!menuShown);
  };
  return (
    <header
      id="header"
      className={`${menuShown ? "opened-menu" : ""} ${
        menuHidden ? "hide-header" : ""
      }`}
    >
      <div className="inner">
        <div className="logo">
          <a href={process.env.REACT_APP_OIKOS_URL}>
            <img src={logo} alt="OIKOS MUSIC" />
          </a>
        </div>
        <div className="menu">
          <nav className="primary">
            <ul>
              <li>
                <a href={process.env.REACT_APP_OIKOS_URL}>TOP</a>
              </li>
              <li>
                <a href={process.env.REACT_APP_OIKOS_URL + "pages/about"}>
                  ABOUT
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                  ALL TUNES
                </a>
              </li>
              <li>
                <a href={process.env.REACT_APP_OIKOS_URL + "pages/artists"}>
                  ARTISTS
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://note.com/oikos_music/"
                  rel="noreferrer"
                >
                  NEWS
                </a>
              </li>
              <li>
                <a href="/">COLLECTION</a>
              </li>
              <li>
                <a href={process.env.REACT_APP_OIKOS_URL + "blogs/club_oikos"}>
                  CLUB OIKOS
                </a>
              </li>
              <li className="sp">
                <a href={process.env.REACT_APP_OIKOS_URL + "pages/faq"}>FAQ</a>
              </li>
            </ul>
          </nav>
          <nav className="secondary">
            <div className="button">
              <a
                className="mypage"
                href={process.env.REACT_APP_OIKOS_URL + "account"}
              >
                {" "}
              </a>
              <a
                className="cart"
                href={process.env.REACT_APP_OIKOS_URL + "cart"}
              >
                {" "}
              </a>
            </div>
          </nav>
        </div>
        <div className="button">
          <a
            className="mypage"
            href={process.env.REACT_APP_OIKOS_URL + "account"}
          >
            {" "}
          </a>
          <a className="cart" href={process.env.REACT_APP_OIKOS_URL + "cart"}>
            {" "}
          </a>
          <button className="menu-button" onClick={clickMenuButton}>
            <i></i>
            <i></i>
            <i></i>
          </button>
        </div>
      </div>
    </header>
  );
};
export default Header;
