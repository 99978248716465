import React, { createContext, useReducer, useEffect } from "react";
import GlobalReducer from "./GlobalReducer";

const initialState = {
  accessToken: null,
};

const GlobalState = ({ children }) => {
  const KEY = "ARTISTOCK_FRONT_DATA";
  const [state, dispatch] = useReducer(
    GlobalReducer,
    initialState,
    (state) => JSON.parse(sessionStorage.getItem(KEY)) || initialState
  );
  useEffect(() => {
    sessionStorage.setItem(KEY, JSON.stringify(state));
  });
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);
export default GlobalState;
