import logo from "./../assets/logo.svg";
const Footer = () => {
  return (
    <footer id="footer">
      <div className="inner">
        <div className="logo">
          <a href={process.env.REACT_APP_OIKOS_URL}>
            <img src={logo} alt="OIKOS MUSIC" />
          </a>
        </div>
        <nav>
          <ul>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/about"}>
                ABOUT OIKOS
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                ALL TUNES
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/artists"}>
                ARTISTS
              </a>
            </li>
            <li>
              <a href="/">COLLECTION</a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "blogs/club_oikos"}>
                CLUB OIKOS
              </a>
            </li>
            {/*
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/joinus"}>
                ARTIST募集
              </a>
            </li>
            */}
            <li>
              <a
                target="_blank"
                href="https://note.com/oikos_music/"
                rel="noreferrer"
              >
                NEWS
              </a>
            </li>
          </ul>
          <ul>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/newsletter"}>
                ニュースレターを登録
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/faq"}>FAQ</a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/contact"}>
                お問い合わせ
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_OIKOS_URL + "policies/privacy-policy"
                }
              >
                プライバシーポリシー
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_OIKOS_URL + "policies/shipping-policy"
                }
              >
                配送ポリシー
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_OIKOS_URL + "policies/refund-policy"
                }
              >
                返金ポリシー
              </a>
            </li>
            <li>
              <a
                href={
                  process.env.REACT_APP_OIKOS_URL + "policies/terms-of-service"
                }
              >
                利用規約
              </a>
            </li>
            <li>
              <a
                href={process.env.REACT_APP_OIKOS_URL + "policies/legal-notice"}
              >
                特商法に基づく表記
              </a>
            </li>
            <li>
              <a href={process.env.REACT_APP_OIKOS_URL + "pages/company-info"}>
                会社概要
              </a>
            </li>
          </ul>
          <ul className="sns">
            <li className="twitter">
              <a
                href="https://twitter.com/OIKOS_MUSIC"
                target="_blank"
                rel="noreferrer"
              >
                TWITTER
              </a>
            </li>
            <li className="facebook">
              <a
                href="https://www.facebook.com/oikosmusic.jp"
                target="_blank"
                rel="noreferrer"
              >
                FACEBOOK
              </a>
            </li>
            <li className="instagram">
              <a
                href="https://www.instagram.com/oikos_music/"
                target="_blank"
                rel="noreferrer"
              >
                INSTAGRAM
              </a>
            </li>
            <li className="youtube">
              <a
                href="https://www.youtube.com/channel/UC_-0sXLqmrCk62W7F8OZnuA"
                target="_blank"
                rel="noreferrer"
              >
                YOUTUBE
              </a>
            </li>
            <li className="note">
              <a
                href="https://note.com/oikos_music"
                target="_blank"
                rel="noreferrer"
              >
                NOTE
              </a>
            </li>
          </ul>
        </nav>
        <div className="extra">
          <div className="copyright">
            Copyright &copy; 2023 OIKOS MUSIC. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
